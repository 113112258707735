*,html{
  font-family: 'Poppins', sans-serif !important;
}
.bg-gradient-logy {
    background-image: linear-gradient(98.24deg, #49b5fd 0%, #3559fa 100%);
  }
.blueSemiCircle{
    width: 72px;
    height: 36px;
    background: linear-gradient(98.24deg, #49b5fd 0%, #3559fa 100%);;
    margin-right: auto;
    margin-left: auto;
    border-radius: 100px 100px 0 0;
}
#pdf-controls{
  display: none;
}
#pdf-page-info{
  display: none;
}
#pdf-page-wrapper{
  margin-top: 0;
}
#header-bar{
  display: none;
}
@media screen and (max-width:600px){
  .phone-hidden{
    display: none;
  }
}
