.testimonialblock{
    width: 33%;
    padding: 1rem;
}
@media screen and (max-width:600px){
    .testimonialblock{
        width: 100%;
    }
    .investorText{
        display: none;
    }
}